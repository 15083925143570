*{
  padding: 0;
  margin: 0;
  box-sizing:border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html,body{
  height:100%;
}

#root{
  height: 100%;
  width: 100%;
}

#header{
  height:100%;
}

#container{
  display: flex;
  flex-direction:row;
  height:100%;
}

#left{
  width:13%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  background-color: lightgray;
  padding:7px;
}

#right{
  width:92%;
  height: 100%;
  padding: 10px;
}

.main-group-container{
  width:50%;
}

input {
  max-width: 100%;
}

input[name="main_group_grade"],input[name="main_group_weight"]{
  width:40%;
  margin: auto;
  display: block;
}

table,th,td{
  border: 2px solid rgb(200, 200, 200);
  border-collapse: collapse;
}

thead {
    background-color: #3f87a6;
    color: #fff;
}

td,
th {
    border: 1px solid rgb(190, 190, 190);
    padding: 5px 10px;
}

td{
  background-color: #d3e9f1;
}

#main-group-form{
  width:100%;
}

#add-class-to-list{
  width:80%;
}

.main-group-input{
  width:100%;
}

.page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #D8BFD8;
  border-style: groove;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 25px rgba(160, 48, 48, 0.2), 0 5px 5px 0 rgba(255, 0, 0, 0.24);
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;

}
